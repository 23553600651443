:root {
  //--color_brand: #883dc2;
  --color_brand: #1f72bd;
}

#filternsv {
  font-size: 16px;

  * {
    box-sizing: border-box;
    vertical-align: middle;
    outline: none;
    line-height: 1rem;
  }

  .flex-between_wrap_no-wrap {
    display: flex;
    justify-content: space-between;
  }

  .flex-end {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .flex_align_center {
    align-items: center;
  }

  .grid {
    display: grid;
  }

  .irs {
    position: relative;
    display: block;
    user-select: none;
  }

  .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
  }

  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    position: absolute;
    top: 0;
    display: block;
  }

  .irs-line-left {
    left: 0;
    width: 11%;
  }

  .irs-line-mid {
    left: 9%;
    width: 82%;
  }

  .irs-line-right {
    right: 0;
    width: 11%;
  }

  .irs-bar {
    position: absolute;
    z-index: 3;
    top: 25px;
    left: 0;
    display: block;
    width: 0;
    height: 5px;
    background-color: var(--color_brand);
  }

  .irs-bar-edge {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  .irs-shadow {
    position: absolute;
    left: 0;
    display: none;
    width: 0;
  }

  .irs-slider {
    position: absolute;
    z-index: 1;
    display: block;
    cursor: default;
  }

  .irs-slider.type_last {
    z-index: 5;
  }

  .irs-slider.from {
    border-right-color: var(--color_brand);
    border-left-color: #fff9;
  }

  .irs-min {
    position: absolute;
    left: 0;
    display: block;
    cursor: default;
  }

  .irs-max {
    position: absolute;
    right: 0;
    display: block;
    cursor: default;
  }

  .irs-from,
  .irs-to,
  .irs-single {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    cursor: default;
    white-space: nowrap;
  }

  .irs-grid {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 20px;
  }

  .irs-with-grid .irs-grid {
    display: block;
  }

  .irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
  }

  .irs-grid-pol.small {
    height: 4px;
  }

  .irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 3px;
    text-align: center;
    white-space: nowrap;
    color: #000;
    font-size: 9px;
    line-height: 9px;
  }

  .irs-disable-mask {
    position: absolute;
    z-index: 2;
    top: 0;
    left: -1%;
    display: block;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0.0);
  }

  .irs-disabled {
    opacity: 0.4;
  }

  .lt-ie9 .irs-disabled {
    filter: alpha(opacity=40);
  }

  .irs-hidden-input {
    position: absolute !important;
    z-index: -9999 !important;
    top: -200px !important;
    left: -200px !important;
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border-style: solid !important;
    border-color: transparent !important;
    outline: none !important;
    background: none !important;
    font-size: 0 !important;
    line-height: 0 !important;
  }

  .irs {
    height: 40px;
  }

  .irs-with-grid {
    height: 60px;
  }

  .irs-line {
    top: 25px;
    height: 5px;
  }

  .irs-line-left {
    height: 5px;
    border-radius: 50px;
    background-color: #f0f2f5;
  }

  .irs-line-mid {
    height: 5px;
    border-radius: 50px;
    background-color: #f0f2f5;
  }

  .irs-line-right {
    height: 5px;
    border-radius: 50px;
    background-color: #f0f2f5;
  }

  .irs-bar-edge {
    top: 25px;
    width: 9px;
    height: 5px;
    background-color: silver;
  }

  .irs-shadow {
    top: 25px;
    height: 5px;
    opacity: 0.25;
    border-radius: 50px;
    background: #000;
  }

  .lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
  }

  .irs-slider {
    z-index: 4;
    top: 17.5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 5px solid rgba(255, 255, 255, .6);
    border-left-color: var(--color_brand);
    border-radius: 50px;
    background-color: var(--color_brand);
  }

  .irs-min,
  .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #fff;
    border-radius: 2px;
    background-color: var(--color_brand);
    text-shadow: none;
    font-size: 10px;
    line-height: 1.333;
  }

  .irs-from,
  .irs-to,
  .irs-single {
    padding: 1px 5px;
    color: #fff;
    border-radius: 2px;
    background-color: var(--color_brand);
    text-shadow: none;
    font-size: 10px;
    line-height: 1.333;

    &::after {
      position: absolute;
      bottom: -6px;
      left: 50%;
      display: block;
      overflow: hidden;
      width: 0;
      height: 0;
      margin-left: -3px;
      content: "";
      border: 3px solid transparent;
      border-top-color: var(--color_brand);
    }
  }

  .irs-grid-pol {
    background-color: silver;
  }

  .irs-grid-text {
    color: #c0c0c0;
  }

  .filter-block__item-count {
    top: 10px !important;
    right: 0;
    text-align: center;
    color: #474747;
    font-size: 13px;
  }

  input[type="checkbox"] {
    display: none;

    + label {
      display: inline-block;
      cursor: pointer;
    }

    &:not(checked) {
      position: absolute;
      opacity: 0;

      + label {
        position: relative;
        width: 100%;
        padding: 0 20px 0 1.3rem;

        &::before {
          background: #e3e3e3;
        }

        &::before,
        &::after {
          position: absolute;
          top: 2px;
          left: 0;
          width: .9rem;
          height: 0.9rem;
          content: '';
          border-radius: 2px;
        }

        &::before {
          background: var(--color_brand);
        }

        &::after {
          border-color: transparent;
          background-color: var(--color_brand);
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }


  .SumoSelect > .optWrapper > .options li label {
    max-width: 85%;
  }

  .price_slider-range {
    margin-top: 10px;
  }

  label {
    font-size: .8rem;
  }

  .form_theme_nsv__twoo-input input {
    width: 50%;
    margin-top: 0;
  }

  .SumoSelect p {
    margin: 0;
  }

  .SumoSelect {
    width: 100%;
  }

  .SelectBox {
    height: 35px;
    padding: 8px;
  }

  .SumoSelect .hidden {
    display: none;
  }

  .SelectClass,
  .SumoUnder {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 0;
    border: none;
  }

  .SelectClass {
    z-index: 1;
  }

  .SumoSelect > .optWrapper > .options li.opt label,
  .SumoSelect > .CaptionCont {
    user-select: none;
  }

  .SumoSelect {
    position: relative;
    display: inline-block;
    outline: none;
  }

  .wrap_filter_block_min,
  .wrap_filter_block_max {
    width: 100%;
    max-width: calc(50% - 5px);
  }

  .SumoSelect > .CaptionCont {
    position: relative;
    width: 100%;
    min-height: 15px;
    margin: 0;
    padding-right: 25px;
    padding-left: 10px;
    border-radius: 3px;
    background-color: #edeef0;
  }

  .SumoSelect > .CaptionCont > span {
    display: block;
    overflow: hidden;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .8rem;
    line-height: 1.1rem;
  }

  .SumoSelect > .CaptionCont > span.placeholder {
    transition: color .3s;
    color: #9c9c9c;
  }

  .SumoSelect > .CaptionCont > label {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    width: 16px;
  }

  .SumoSelect > .CaptionCont > label > i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    transition: opacity .3s;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
  }

  .SumoSelect:hover label > i {
    opacity: 1;
  }

  .SumoSelect:hover span.placeholder {
    color: #000;
  }

  .tab-filter .SumoSelect > .optWrapper {
    position: relative;
    top: 0;
    display: block;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  .tab-filter .SumoSelect > .CaptionCont {
    display: none;
  }

  .tab-filter .SumoSelect > .optWrapper > .options {
    max-height: none;
  }

  .tab-filter .SumoSelect > .optWrapper > .options li label {
    max-width: 100%;
    padding-right: 20px;
  }

  .tab-filter .SumoSelect > .optWrapper.multiple > .options li.opt {
    display: inline-block;
  }
}

#filternsv .SumoSelect > .optWrapper {
  position: absolute;
  z-index: 1000;
  top: 32px;
  left: 0;
  display: none;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 20px #e3e3e3;
}

#filternsv .SumoSelect.open > .optWrapper {
  display: block;
}

#filternsv .SumoSelect.open > .optWrapper.up {
  top: auto;
  bottom: 100%;
  margin-bottom: 5px;
}

#filternsv .SumoSelect > .optWrapper ul {
  display: block;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

#filternsv .SumoSelect > .optWrapper > .options {
  position: relative;
  max-height: 250px;
}

#filternsv .SumoSelect > .optWrapper > .options li.group.disabled > label {
  opacity: 0.5;
}

#filternsv .SumoSelect > .optWrapper > .options li ul li.opt {
  padding-left: 22px;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt {
  position: relative;
  padding: 10px;
}

#filternsv .SumoSelect > .optWrapper > .options > li.opt:last-child {
  border-bottom: none;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt:hover {
  background-color: #efefef;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt.sel {
  border-bottom: 1px solid #a1c0e4;
  background-color: #a1c0e4;
}

#filternsv .SumoSelect > .optWrapper > .options li label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#filternsv .SumoSelect > .optWrapper > .options li span {
  display: none;
}

#filternsv .SumoSelect > .optWrapper > .options li.group > label {
  padding: 8px 6px;
  cursor: default;
  font-weight: bold;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt.disabled {
  pointer-events: none;
  background-color: inherit;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt.disabled * {
  opacity: 0.5;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt span {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 30px;
  margin-left: -35px;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt span i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: .9rem;
  height: .9rem;
  margin: auto;
  border-radius: 2px;
  background-color: #e3e3e3;
}

#filternsv,
#filternsv-text-1,
#filternsv-text-2,
#filternsv-text-cat {
  margin-top: 15px;
}

#filternsv input[type="checkbox"]:checked + label:after,
#filternsv input[type="radio"]:checked + label:after,
#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt.selected span i,
#filternsv .SumoSelect .select-all.selected > span i,
#filternsv .SumoSelect .select-all.partial > span i {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 16 16'%3E%3Cpath d='M6 10.813l7.063-7.094 0.938 0.938-8 8-3.719-3.719 0.906-0.938z'/%3E%3C/svg%3E");
}

#filternsv .SumoSelect > .CaptionCont > label > i {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 9.22 5.42'%3E%3Cpath d='M.24.28A.85.85,0,0,1,1.44.22L1.5.28l3.13,3,3.13-3A.85.85,0,0,1,9,.25l0,0A1,1,0,0,1,9,1.57c-.32.33-3.76,3.6-3.76,3.6A.88.88,0,0,1,4,5.17S.57,1.91.24,1.57A1,1,0,0,1,.24.28Z'/%3E%3C/svg%3E");
}

#filternsv .show_search_text {
  width: 100%;
  padding: 5px;
  border: 1px solid #edeef0;
  font-size: .9rem;
}

#filternsv .show_search_text:not(:first-child) {
  margin-top: 5px;
}

.datepicker--cells {
  display: flex;
  flex-wrap: wrap
}

.datepicker--cell {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  font-size: .8rem;
}

.datepicker--cell.-focus- {
  background: #f0f0f0
}

.datepicker--cell.-current- {
  color: var(--color_brand)
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a
}

.datepicker--cell.-current-.-in-range- {
  color: var(--color_brand)
}

.datepicker--cell.-in-range- {
  color: #4a4a4a;
  border-radius: 0;
  background: rgba(92, 196, 239, .1);
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, .2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, .5);
  border-radius: 4px 0 0 4px;
  background-color: rgba(92, 196, 239, .1);
}

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, .5);
  border-radius: 0 4px 4px 0;
  background-color: rgba(92, 196, 239, .1);
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
  color: #fff;
  background: var(--color_brand);
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}

.datepicker--cell.-selected- {
  border: none;
}

.datepicker--cell.-selected-.-focus- {
  opacity: .7;
  background: var(--color_brand);
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--color_brand);
  font-size: .6rem;
}

.-only-timepicker- .datepicker--content, .datepicker--body, .datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--cell-day {
  width: 14.28571%;
}

.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--cells-years, .datepicker--years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepickers-container {
  position: absolute;
  top: 0;
  left: 0;
}

@media print {
  .datepickers-container {
    display: none;
  }
}

.datepicker {
  position: absolute;
  z-index: 100;
  left: -100000px;
  box-sizing: content-box;
  width: 250px;
  transition: opacity .3s ease, transform .3s ease, left 0s .3s;
  opacity: 0;
  color: #4a4a4a;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  font-family: Tahoma, sans-serif;
  font-size: 14px;
}

.datepicker.-from-top- {
  transform: translateY(-8px);
}

.datepicker.-from-right- {
  transform: translateX(8px);
}

.datepicker.-from-bottom- {
  transform: translateY(8px);
}

.datepicker.-from-left- {
  transform: translateX(-8px);
}

.datepicker.active {
  transition: opacity .3s ease, transform .3s ease, left 0s 0s;
  transform: translate(0);
  opacity: 1;
}

.datepicker-inline .datepicker {
  position: static;
  right: auto;
  left: auto;
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  border-color: #d7d7d7;
  box-shadow: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}

.datepicker--pointer {
  position: absolute;
  z-index: -1;
  width: 10px;
  height: 10px;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  background: #fff;
}

.datepicker--nav-action:hover, .datepicker--nav-title:hover {
  background: #f0f0f0;
}

.-top-center- .datepicker--pointer,
.-top-left- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  transform: rotate(135deg);
}

.-right-bottom- .datepicker--pointer,
.-right-center- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  right: calc(100% - 4px);
  transform: rotate(225deg);
}

.-bottom-center- .datepicker--pointer,
.-bottom-left- .datepicker--pointer,
.-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  transform: rotate(315deg);
}

.-left-bottom- .datepicker--pointer,
.-left-center- .datepicker--pointer,
.-left-top- .datepicker--pointer {
  left: calc(100% - 4px);
  transform: rotate(45deg);
}

.-bottom-left- .datepicker--pointer, .-top-left- .datepicker--pointer {
  left: 10px;
}

.-bottom-right- .datepicker--pointer, .-top-right- .datepicker--pointer {
  right: 10px;
}

.-bottom-center- .datepicker--pointer, .-top-center- .datepicker--pointer {
  left: calc(50% - 10px / 2);
}

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2);
}

.datepicker--body.active {
  display: block;
}

.datepicker--nav {
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  padding: 4px;
  border-bottom: 1px solid #efefef;
}

.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-action, .datepicker--nav-title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: .8rem;
}

.datepicker--nav-action {
  width: 32px;
  user-select: none;
  border-radius: 4px;
}

.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}

.datepicker--nav-title {
  padding: 0 8px;
  border-radius: 4px;
}

.datepicker--buttons, .datepicker--time {
  padding: 4px;
  border-top: 1px solid #efefef;
}

.datepicker--nav-title i {
  margin-left: 5px;
  color: #9c9c9c;
  font-style: normal;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: 0 0;
}

.datepicker--buttons {
  display: flex
}

.datepicker--button {
  display: inline-flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  height: 32px;
  cursor: pointer;
  color: var(--color_brand);
  border-radius: 4px;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.datepicker--time {
  position: relative;
  display: flex;
  align-items: center;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  flex: 0 1 138px;
  max-width: 138px;
}

.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  flex: 0 1 153px;
  max-width: 153px;
  margin-right: 10px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 0 0 10px;
  text-align: center;
  font-size: 14px;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours, .datepicker--time-current-minutes {
  position: relative;
  z-index: 1;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: 19px;
  line-height: 1;
}

.datepicker--time-current-hours:after, .datepicker--time-current-minutes:after {
  position: absolute;
  z-index: -1;
  top: -3px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  content: '';
  opacity: 0;
  border-radius: 4px;
  background: #f0f0f0;
}

.datepicker--time-current-hours.-focus-:after, .datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  align-self: flex-end;
  margin-bottom: 1px;
  margin-left: 6px;
  text-transform: uppercase;
  color: #9c9c9c;
  font-size: 11px;
}

.datepicker--time-row {
  display: flex;
  align-items: center;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
  font-size: 11px;
}

.datepicker--time-row:first-child {
  margin-bottom: 4px;
}

.datepicker--time-row input[type=range] {
  flex: 1;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}

.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:focus {
  outline: 0;
}

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  border-color: var(--color_brand);
  background: var(--color_brand);
}

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  border-color: var(--color_brand);
  background: var(--color_brand);
}

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  border-color: var(--color_brand);
  background: var(--color_brand);
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  cursor: pointer;
  transition: background .2s;
  border: 1px solid #dedede;
  border-radius: 3px;
  background: #fff;
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: background .2s;
  border: 1px solid #dedede;
  border-radius: 3px;
  background: #fff;
}

.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: background .2s;
  border: 1px solid #dedede;
  border-radius: 3px;
  background: #fff;
}

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  height: 1px;
  cursor: pointer;
  color: transparent;
  border: none;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-moz-range-track {
  height: 1px;
  cursor: pointer;
  color: transparent;
  border: none;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-track {
  height: 1px;
  cursor: pointer;
  color: transparent;
  border: none;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: 0 0;
}

.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  position: relative;
  width: 1rem;
  height: 1em;
  margin: 0 5px -1px 0;
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
}

.datepicker--time-icon:after, .datepicker--time-icon:before {
  position: absolute;
  content: '';
  background: currentColor;
}

.datepicker--time-icon:after {
  top: calc(50% + 1px);
  left: calc(50% - 1px);
  width: 1px;
  height: .4rem;
  transform: translateY(-100%)
}

.datepicker--time-icon:before {
  top: calc(50% + 1px);
  left: calc(50% - 1px);
  width: .4rem;
  height: 1px;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  color: #ccc;
  background-color: rgba(92, 196, 239, .1);
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, .2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  border: none;
  background: 0 0;
}

#filternsv .shop-search {
  padding: 15px;
  background: #fff;
}

#filternsv .shop-search .filter_blocks .filter_elInput_toFrom {
  width: 100%;
  max-width: 50%;
  margin-top: 0;
}

#filternsv .shop-search .shop_search_checkbox {
  border-radius: 3px;
  background-color: #edeef0;
}

#filternsv .shop-search .shop_search_checkbox label {
  width: 100%;
  height: 35px;
  margin: 0;
}

#filternsv .shop-search .shop_search_checkbox label span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .8rem;
}

#filternsv .shop-search .shop_search_checkbox.disabled label span {
  color: #9c9da0;
}

#filternsv .shop-search .shop_search_checkbox.disabled label {
  cursor: no-drop;
}

#filternsv .shop-search .shop_search_checkbox input[type="checkbox"]:not(checked) + label {
  padding: 10px 8px 10px calc(8px + 1.3em);
}

#filternsv .shop-search .shop_search_checkbox input[type="checkbox"]:not(checked) + label::before,
#filternsv .shop-search .shop_search_checkbox input[type="checkbox"]:not(checked) + label::after {
  top: 10px;
  left: 8px;
}

#filternsv .shop-search .shop_search_checkbox_data-count {
  margin-left: 5px;
}

#filternsv .loaded-nsv-ajax.loaded-nsv {
  overflow: visible;
}

#filternsv .loaded-nsv-ajax.loaded-nsv .loaded-nsv__children {
  z-index: 1000;
  background-color: rgba(255, 255, 255, .7);
}

#filternsv .wrap_filter_block_min .SumoSelect .CaptionCont {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#filternsv .wrap_filter_block_max .SumoSelect .CaptionCont {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#filternsv .disabled {
  color: silver;
}

#filternsv .sumoStopScroll {
  overflow: hidden;
}

#filternsv .SumoSelect .search-txt {
  display: none;
  outline: none;
}

#filternsv .SumoSelect .no-match {
  display: none;
  padding: 6px;
}

#filternsv .SumoSelect.open .search-txt {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 8px;
  border: none;
  font-size: .8rem;
  line-height: 1.1rem;
}

#filternsv .SumoSelect.open > .search > span,
#filternsv .SumoSelect.open > .search > label {
  visibility: hidden;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li ul li.opt {
  padding-left: 50px;
}

#filternsv .SumoSelect > .optWrapper.isFloating > .options {
  max-height: 100%;
}

#filternsv .SumoSelect > .optWrapper.isFloating {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  max-height: 90%;
  margin: auto;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt {
  padding-left: 35px;
  cursor: pointer;
}

#filternsv .SumoSelect > .optWrapper > .options li.opt span,
#filternsv .SumoSelect .select-all > span {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 30px;
  margin-left: -35px;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt span i,
#filternsv .SumoSelect .select-all > span i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: .9rem;
  height: .9rem;
  margin: auto;
  border-radius: 2px;
  background-color: #e3e3e3;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt.selected span i,
#filternsv .SumoSelect .select-all.selected > span i,
#filternsv .SumoSelect .select-all.partial > span i {
  border-color: transparent;
  background-color: var(--color_brand);
  background-repeat: no-repeat;
  background-position: center center;
}

#filternsv .SumoSelect > .optWrapper > .MultiControls {
  display: none;
  border-top: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
}

#filternsv .SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  margin-top: 5px;
}

#filternsv .SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
  display: block;
}

#filternsv .SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
  padding: 6px;
}

#filternsv .SumoSelect > .optWrapper.multiple > .MultiControls > p {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 12px;
  cursor: pointer;
  text-align: center;
}

#filternsv .SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
  background-color: #f1f1f1;
}

#filternsv .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
  border-right: 1px solid #dbdbdb;
}

#filternsv .SumoSelect > .optWrapper.isFloating > .options li.opt {
  padding: 12px 6px;
}

#filternsv .SumoSelect > .optWrapper.multiple.isFloating > .options li.opt {
  padding-left: 35px;
}

#filternsv .SumoSelect > .optWrapper.multiple.isFloating {
  padding-bottom: 43px;
}

#filternsv .SumoSelect .select-all {
  position: relative;
  box-sizing: content-box;
  height: 20px;
  padding: 8px 0 3px 35px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background-color: #f6f6f6;
}

#filternsv .SumoSelect .select-all > label,
#filternsv .SumoSelect .select-all > span i {
  cursor: pointer;
}

#filternsv .SumoSelect .select-all.partial > span i {
  background-color: #ccc;
}

#filternsv .SumoSelect > .optWrapper > .options li.optGroup {
  padding-left: 5px;
  text-decoration: underline;
}

#filternsv .width-100 {
  width: 100%;
}

#filternsv .width-max-50 {
  max-width: 50%;
}

#filternsv .margin-bottom-15 {
  margin-bottom: 15px;
}

#filternsv .btn-nsv-clear {
  padding: 10px 20px;
  text-decoration: none;
  text-transform: none;
  color: #fff;
  border-radius: 3px;
  background-color: var(--color_brand);
  font-size: .8rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#filternsv *[class*=icon-nsv] {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: 100% 100%;
  font-size: 1rem;
}

.loaded-nsv {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  background: rgb(237, 238, 240) !important;
}

.loaded-nsv *:not(.loaded-nsv__item) {
  position: absolute;
  opacity: 0;
}

.loaded-nsv__item {
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  bottom: 0;
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  -webkit-animation: spin .7s linear infinite;
  animation: spin .7s linear infinite;
  border-top: 3px solid var(--color_brand);
  border-right: 3px solid rgb(237, 238, 240);
  border-bottom: 3px solid silver;
  border-left: 3px solid rgb(237, 238, 240);
  border-radius: 50%;
}


#filternsv .SumoSelect > .CaptionCont {
  border: 1px solid #ededed;
  border-radius: 0;
  background-color: #fafafa;
}

#filternsv .SumoSelect > .optWrapper.multiple > .options li.opt span i,
#filternsv .SumoSelect .select-all > span i {
  border: 1px solid #dee4ed;
  border-radius: 0;
  background: #fff;
}

#filternsv .filter {
  padding-bottom: 20px;
}

#filternsv .tab-filter .SumoSelect > .optWrapper {
  display: flex;
  align-items: center;
  height: 48px;
}


/* нафиг надо разбираться с этим говном. переназначу стили */

@mixin nn-webkit-scrollbar($color, $background: transparent, $size: 3px) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-button {
    display: none;
    //background: none;
    //size: $size;
    //height: $size;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: $background;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color;

    &:hover {
      background-color: $color;
    }
  }
  &::-webkit-resizer {
    display: none;
    //background: none;
    //size: $size;
    //height: $size;
  }
}

body {
  $border-color: #eee;
  $color-main: #212121;
  $color-primary: #0277bd;
  $color-accent: #00c853;

  #filternsv .SumoSelect {
    > .CaptionCont {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      user-select: none;
      text-align: left;
      color: $color-main;
      border: 1px solid $border-color;
      border-radius: 0;
      background: #fafafa;
      -webkit-touch-callout: none;

      > span.placeholder {
        color: inherit;
        font-size: 13px;
        line-height: 22px;
      }
    }

    > .optWrapper {
      top: calc(100% - 1px);
      overflow: hidden;
      overflow-y: auto;
      max-height: 187px;
      //display: none;
      //position: absolute;
      margin: 0;
      //margin-left: -1px;
      //width: calc(100% + 2px);
      padding-top: 6px;
      border: 1px solid $border-color;
      border-radius: 2px;
      background: #fff;
      //top: 100%;
      //left: 0;
      //list-style: none;
      //padding: 0;
      box-shadow: 0 10px 20px rgba(black, 0.1);
      font-size: 13px;
      @include nn-webkit-scrollbar(#a7bad1, #fff, 5px);
      line-height: 22px;

      > .options {
        max-height: none;

      }

      > .options li {
        &.opt {
          padding-right: 20px;

          &:hover {
            background: none;
          }
        }

        label {
          color: #999;
        }

        &:hover label {
          color: $color-primary;
        }
      }

      > .options li.opt span {
        padding-right: 5px;
        text-align: right;
        font-size: 11px;
      }
    }

    > .optWrapper.multiple > .options li.opt span i {
      width: 16px;
      height: 16px;
      transition: 0.3s ease;
      color: $color-primary;
      border: 1px solid #dfe5ee;
      border-radius: 0;
      background: #fff;
    }

    > .optWrapper.multiple > .options li.opt:hover span i {
      box-shadow: 0 0 0 2px $color-accent;
    }

    > .optWrapper.multiple > .options li.opt.selected span i {
      border-color: #dfe5ee;
      background-color: #fff;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiPiAgICA8cGF0aCBmaWxsPSIjMDI3N2JkIiBkPSJNNDQ0LjMsNjMuOGw2Ny43LDY3LjdMMTk1LjMsNDQ4LjJMMCwyNTNsNjcuNy02Ny43bDEyNy42LDEyNy42TDQ0NC4zLDYzLjh6Ii8+PC9zdmc+);
      background-size: 12px 12px;
    }
  }
}
